body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  background-color: var(--grey-1);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

